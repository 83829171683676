
  <div class="about">
    <img style="width:100%" src="https://qiniu.qsmiaoyu180.cn/website/kctx%402x.png" alt="">
    <div class="copntainer">



      <div class="frist">
        <div class="txt">
          <p> 妙语教育开发的青少年语言能力提升课程体系，是由多年从事青少年语言教育的专家、名师团队耗时四年所完成，仅小学段课程文本就超过300万字，得到国家语委/全球中文学习平台的认可，并签署框架合作协议；
          </p>
          <p>
            妙语教育开发此课程体系的初衷，就是帮助广大中小学生家长去培养孩子的语言能力。在教育部/国家语委制定的相关标准（国家标准只有成人/中小学生的“读”和“讲”的测评标准）的基础上，制定和完善了【听】【读】【背】【诵】【讲】全部五项测评标准。全面体系化的学习内容，循序渐进学习方法，使语言学习更加科学有
          </p>
        </div>
        <img class="kctx_01" src="https://qiniu.qsmiaoyu180.cn/website/kctx_01%402x.png" alt="">
      </div>
      <img style="width:100%" src="https://qiniu.qsmiaoyu180.cn/website/bigimg.png" alt="">

      <div class="list">
        <div class="item">
          <img class="itemImg" src="../assets/img/curriculumSystem/t@2x.png" alt="">
          <p>
            语言学习是人们言语交际必备的一种能力。而正确的听则是人们交流时捕捉信息、做出判断、妥当回应的基础。若是忽略了这个基础，丢三落四的听，捕风捉影的思，那么回应也一定是不着边际的。因此，系统细致地训练听的能力，是学习语言的第一步。
          </p>
        </div>
        <div class="item" style="marginLeft:3%">
          <img class="itemImg" src="../assets/img/curriculumSystem/d@2x.png" alt="">
          <p>
            在语言学习上，读，既是学习的手段，又是学习的目的。正确的读不仅仅用语音再现某些文字，而是要通过恰当的节奏和停顿、贴切的语调和语气，较完美地表现文本的内容，以期与作者共鸣并表达出自己独特的解读。若想企及这样的境界，不经过正规、完备的训练是实现不了的。
          </p>
        </div>
        <div class="item" style="marginLeft:3%">
          <img class="itemImg" src="../assets/img/curriculumSystem/b@2x.png" alt="">
          <p>
            “背”是识记的必备手段，是积累知识的第一步。学前到中小学教育阶段，正是学童们记忆的黄金期，采取必要的方式方法，激励学童去主动识记、大量积累，这对他们丰富知识、熏染文化、形成气质都是大有裨益的。
          </p>
        </div>
        <div class="item">
          <img class="itemImg" src="../assets/img/curriculumSystem/s@2x.png" alt="">
          <p>
            即指“朗诵”，是在深入理解文本的基础上，加之个性化的解读，用字正腔圆的语音、抑扬顿挫的语调、快慢适宜的节奏和丰富饱满的情感，朗声诵读文本，并以此致敬作者、感染读者，达到心心相印的效果，获得如愿以偿般的审美感受。
          </p>
        </div>
        <div class="item" style="marginLeft:3% ;width: 50%;">
          <img class="itemImg" src="../assets/img/curriculumSystem/j@2x.png" alt="">
          <p>
            “讲”是一种心声的表达，是将自己的所思所感用恰当的语言明白的表述出来，以达成沟通心曲的目的。正确完美的“讲述”有三个境界，一是在展露淳朴认知、简洁交际时保持着童心童趣；二是在宣讲移风易俗、亲善逐美时立足真情实感；三是在传达明辨是非、阐事明理时凸显真知灼见。要达到这三层境界并非易事，要想流畅的讲述，必须深刻的思考、精妙的辨析，更要借助丰富的知识积累和精彩的语言表达。
          </p>
        </div>

      </div>

    </div>


  </div>
